import dayjs from 'dayjs';

export function getDayjsLocale(locale?: string) {
  if (locale) {
    const lowerLocale = locale.toLocaleLowerCase();
    try {
      require('dayjs/locale/' + lowerLocale + '.js');
      dayjs.locale(lowerLocale);
    } catch (error) {
      const baseLocale = locale.slice(0, 2);
      try {
        require('dayjs/locale/' + baseLocale + '.js');
        dayjs.locale(baseLocale);
      } catch (error) {
        require('dayjs/locale/en.js');
        dayjs.locale('en');
      }
    }
  }
}

export function getLocale(): string {
  const locale = localStorage.getItem('locale');
  return locale ? locale : 'en';
}

export function getAntdLocale() {
  const currentLocale = getLocale();
  let antdLocale;
  try {
    antdLocale = require('antd/lib/locale/' + currentLocale + '.js').default;
  } catch (error) {
    let baseLocale = currentLocale.slice(0, 2);
    let standardLocale;

    /*
     * Map languages to locales available in antd.
     * By default, some languages such as fr or de are easily mapped to fr_FR or de_DE but other languages have no default locale, such as en_EN. Those languages must be listed in the table below with the most probable locale.
     */
    const antdMap: { [key: string]: string } = {
      en: 'en_US',
      hy: 'hy_MA',
    };

    if (antdMap.hasOwnProperty(baseLocale)) {
      standardLocale = antdMap[baseLocale];
    } else {
      standardLocale =
          baseLocale.toLowerCase() + '_' + baseLocale.toUpperCase();
    }

    try {
      antdLocale = require('antd/lib/locale/' + standardLocale + '.js').default;
    } catch (error) {
      antdLocale = require('antd/lib/locale/en_US.js').default;
    }
  }

  return antdLocale;
}
