import React, { useEffect, useState } from 'react';
import { Result, Button } from 'antd';
import { Spin } from 'antd';
import Translator from '../../services/translator';
import { FallbackProps } from 'react-error-boundary';
import './ErrorPage.css';

interface ErrorPageProps {
  exception: FallbackProps;
}

const ErrorPage = (props: ErrorPageProps) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (loading) {
      window.location.reload();
    }
  }, [loading]);

  return (
    <div className="error-page-container">
      <Spin spinning={loading}>
        <Result
          className="error-informations"
          status="500"
          title={Translator.trans('errorPage.somethingWentWrong')}
          subTitle={props.exception.error.message}
          extra={
            <Button onClick={() => setLoading(true)} type="primary">
              {Translator.trans('errorPage.buttonReload')}
            </Button>
          }
        />
      </Spin>
    </div>
  );
};

export default ErrorPage;
