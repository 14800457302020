import React, { useState } from 'react';
import audienceLogo from '../../assets/img/logo/audience.png';
import dashboardScreenshot from '../../assets/img/screenshots/dashboard.png';
import ticketingScreenshot from '../../assets/img/screenshots/ticketing.png';
import RegisterOrganizer from '../../components/Form/RegisterOrganizer';
import RegisterEvent from '../../components/Form/RegisterEvent';
import Translator from '../../services/translator';
import { getUrl, post } from '../../services/http';
import Organizer from '../../models/Organizer';
import Event from '../../models/Event';
import FlagSelection from '../../components/FlagSelection/FlagSelection';
import { getLocale } from '../../services/getTempLocale';
import { LeftOutlined } from '@ant-design/icons';
import { NotificationManager } from '../../components/NotificationManager/NotificationManagment';
import './index.css';

enum STEPS {
  'FORM_ORGANIZER',
  'FORM_EVENT',
  'FINISH',
}

const fieldsStep: any = {
  organizationName: STEPS.FORM_ORGANIZER,
  firstName: STEPS.FORM_ORGANIZER,
  lastName: STEPS.FORM_ORGANIZER,
  email: STEPS.FORM_ORGANIZER,
  eventName: STEPS.FORM_EVENT,
  eventType: STEPS.FORM_EVENT,
  eventDateStart: STEPS.FORM_EVENT,
  eventDateStop: STEPS.FORM_EVENT,
  eventAddress: STEPS.FORM_EVENT,
};

export const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState<STEPS>(STEPS.FORM_ORGANIZER);
  const [formErrors, setFormErrors] = useState<any>([]);
  const [formOrganizer, setFormOrganizer] = useState<Organizer>(
    new Organizer()
  );

  function handleSubmitRegisterOrganizer(formValues: Organizer) {
    setFormErrors([]);
    setFormOrganizer(formValues);
    setCurrentStep(STEPS.FORM_EVENT);
  }

  function handleSubmitRegisterEvent(formValues: Event) {
    setFormErrors([]);
    setIsLoading(true);
    const formatedPayload = {
      email: formOrganizer.email,
      eventType: formValues.eventType,
      firstName: formOrganizer.firstName,
      lastName: formOrganizer.lastName,
      eventName: formValues.eventName,
      eventDateStart: formValues.eventDateStart,
      eventDateStop: formValues.eventDateStop,
      organizationName: formOrganizer.organizationName,
      eventAddress: formValues.eventAddress,
    };
    post(
      getUrl(
        'organizations/register?platform=ticketing&_locale=' + getLocale()
      ),
      formatedPayload
    )
      .then(() => {
        setCurrentStep(STEPS.FINISH);
      })
      .catch((error) => {
        setFormErrors(error.body.violations);
        goToError(error.body.violations);
        NotificationManager.error(error.body.detail);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function goToError(errors: any[]) {
    if (errors && errors.length > 0) {
      errors.forEach((error: { propertyPath: string }) => {
        if (fieldsStep.hasOwnProperty(error.propertyPath)) {
          setCurrentStep(fieldsStep[error.propertyPath]);
        }
      });
    }
  }

  return (
    <div
      className={
        'container-signup' +
        (currentStep === STEPS.FINISH ? ' signup-success' : '')
      }
    >
      {currentStep === STEPS.FINISH ? (
        <div className={'container-signup-success'}>
          <div>{Translator.trans('signupSuccess.title')}</div>
          <div>{Translator.transHtml('signupSuccess.desc')}</div>
        </div>
      ) : (
        <>
          <div className={'left-part'}>
            <div className={'container-screenshot'}>
              <img
                className={'dashboard-screenshot'}
                src={
                  currentStep === STEPS.FORM_ORGANIZER
                    ? dashboardScreenshot
                    : ticketingScreenshot
                }
                alt={'dashboard-screenshot'}
              />
              <div className={'screenshot-title'}>
                {currentStep === STEPS.FORM_ORGANIZER
                  ? Translator.trans('screenshot.dashboard.title')
                  : Translator.trans('screenshot.ticketing.title')}
              </div>
              <div className={'screenshot-description'}>
                {currentStep === STEPS.FORM_ORGANIZER
                  ? Translator.trans('screenshot.dashboard.desc')
                  : Translator.trans('screenshot.ticketing.desc')}
              </div>
            </div>
          </div>
          <div className={'right-part'}>
            <div className={'container-signin'}>
              <FlagSelection />
              {currentStep === STEPS.FORM_EVENT && (
                <LeftOutlined
                  className={'back-button'}
                  onClick={() => setCurrentStep(STEPS.FORM_ORGANIZER)}
                />
              )}
              <img
                className={'audience-logo'}
                src={audienceLogo}
                alt={'logo'}
              />
              {currentStep === STEPS.FORM_ORGANIZER && (
                <RegisterOrganizer
                  errors={formErrors}
                  formOrganizer={formOrganizer}
                  submitForm={handleSubmitRegisterOrganizer}
                />
              )}
              {currentStep === STEPS.FORM_EVENT && (
                <RegisterEvent
                  errors={formErrors}
                  submitForm={handleSubmitRegisterEvent}
                  isLoading={isLoading}
                />
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SignUp;
