import React, { useEffect, useRef, useState } from 'react';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import Translator from '../../../services/translator';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
const { RangePicker } = DatePicker;
import { geocodeByPlaceId } from 'react-google-places-autocomplete';
import Event from '../../../models/Event';
import EventAddress from '../../../models/EventAddress';
import dayjs from 'dayjs';
import { getAntdLocale } from "../../../services/getTempLocale";

interface RegisterEventProps {
  submitForm: (values: Event) => void;
  isLoading: boolean;
  errors: any;
}

export const RegisterEvent = (props: RegisterEventProps) => {
  const [eventType, setEventType] = useState('');
  const [eventName, setEventName] = useState('');
  const [eventDateStart, setEventDateStart] = useState('');
  const [eventDateStop, setEventDateStop] = useState('');
  const [addressError, setAddressError] = useState(false);
  const [eventAddress, setEventAddress] = useState<EventAddress>(
    new EventAddress()
  );
  const [submittable, setSubmittable] = React.useState(false);
  const [form] = Form.useForm();
  const formRef = useRef<any>(null);
  const dateNow = useRef(dayjs().add(1, 'day'));

  const eventAddressObj = useRef<EventAddress>(new EventAddress());

  useEffect(() => {
    handleDisplayErrors(props.errors);
  }, [props.errors]);

  function handleDisplayErrors(errors: any) {
    let setErrors: any = [];

    if (errors && errors.length > 0) {
      errors.forEach((error: any) => {
        setErrors.push({
          name: error.propertyPath,
          errors: [error.title],
        });
      });
    }

    if (setErrors.length > 0) {
      formRef.current?.setFields(setErrors);
      formRef.current?.scrollToField(setErrors[0].name);
    }
  }

  function handleChangeEventName(value: { target: { value: string } }) {
    setEventName(value.target.value);
  }

  function handleChangeEventType(value: string) {
    setEventType(value);
  }

  function handleChangeEventAddress(obj: any) {
    geocodeByPlaceId(obj.value.place_id).then((results: any) => {
      formatAddress(results[0].address_components);
    });
  }

  function formatAddress(results: any) {
    const address: EventAddress = new EventAddress();

    console.log(results);

    results.forEach((data: any) => {
      if (data.types.includes('street_number')) {
        address.number = data.long_name;
      } else if (data.types.includes('route')) {
        address.street = data.long_name;
      } else if (data.types.includes('locality')) {
        address.city = data.long_name;
      } else if (data.types.includes('postal_code')) {
        address.postalCode = data.long_name;
      } else if (data.types.includes('administrative_area_level_1')) {
        address.locality = data.long_name;
      } else if (data.types.includes('country')) {
        address.country = data.short_name;
      }

      eventAddressObj.current = address;
    });
    setEventAddress(address);
    setAddressError(!address.street || !address.locality || !address.country);
  }

  function handleChangeEventDatesEvent(value: any) {
    if (value) {
      setEventDateStart(
        new Date(value[0].$d.setHours(0, 0, 0, 0)).toISOString()
      );
      setEventDateStop(
        new Date(value[1].$d.setHours(23, 59, 0, 0)).toISOString()
      );
    } else {
      setEventDateStart('');
      setEventDateStop('');
    }
  }

  useEffect(() => {
    if (
      eventType &&
      eventName &&
      eventDateStart &&
      eventDateStop &&
      eventAddress &&
      !addressError &&
      eventAddress.street &&
      eventAddress.locality &&
      eventAddress.country
    ) {
      setSubmittable(true);
    } else {
      setSubmittable(false);
    }
  }, [
    eventType,
    eventName,
    eventDateStart,
    eventDateStop,
    eventAddress,
    addressError,
  ]);

  function handleRemoveErrors(obj: any) {
    let errors: any = [];

    Object.keys(obj).map((key) =>
      errors.push({
        name: key,
        errors: '',
      })
    );

    formRef.current?.setFields(errors);
  }

  function handleSubmit() {
    handleRemoveErrors(formRef.current?.getFieldsValue());
    props.submitForm({
      eventName,
      eventType,
      eventDateStart,
      eventDateStop,
      eventAddress: eventAddressObj.current,
    });
  }

  useEffect(() => {
    form.validateFields(['address']);
  }, [addressError, form]);

  return (
    <div className={'container-form'}>
      <div className={'form-title'}>
        {Translator.trans('form.registerEvent.title')}
      </div>
      <div className={'form-desc'}>
        {Translator.trans('form.registerEvent.desc')}
      </div>
      <div>
        <Form
          ref={formRef}
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label={Translator.trans('form.registerEvent.input.name')}
            name="name"
            htmlFor={'text'}
            rules={[
              {
                required: true,
                message: Translator.trans('field.required'),
              },
            ]}
          >
            <Input onChange={handleChangeEventName} />
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerEvent.input.type')}
            name={'eventType'}
            rules={[
              {
                required: true,
                message: Translator.trans('field.required'),
              },
            ]}
          >
            <Select onChange={handleChangeEventType}>
              <Select.Option value="festival">
                {Translator.trans('eventType.festival')}
              </Select.Option>
              <Select.Option value="concert">
                {Translator.trans('eventType.concert')}
              </Select.Option>
              <Select.Option value="charity">
                {Translator.trans('eventType.charity')}
              </Select.Option>
              <Select.Option value="sport">
                {Translator.trans('eventType.sport')}
              </Select.Option>
              <Select.Option value="culture">
                {Translator.trans('eventType.culture')}
              </Select.Option>
              <Select.Option value="professional">
                {Translator.trans('eventType.professional')}
              </Select.Option>
              <Select.Option value="private">
                {Translator.trans('eventType.private')}
              </Select.Option>
              <Select.Option value="webinar">
                {Translator.trans('eventType.webinar')}
              </Select.Option>
              <Select.Option value="workshop">
                {Translator.trans('eventType.workshop')}
              </Select.Option>
              <Select.Option value="test">
                {Translator.trans('eventType.test')}
              </Select.Option>
              <Select.Option value="other">
                {Translator.trans('eventType.other')}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerEvent.input.dates')}
            name={'dates'}
            rules={[
              {
                required: true,
                message: Translator.trans('field.required'),
              },
            ]}
          >
            <RangePicker
              onChange={handleChangeEventDatesEvent}
              className={'datePicker'}
              disabledDate={(current) => dateNow.current > current}
              format={getAntdLocale().DatePicker.lang.dateFormat}
            />
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerEvent.input.address')}
            name="address"
            initialValue={eventAddress}
            rules={[
              {
                validator: () =>
                  !addressError
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          Translator.trans(
                            'form.registerEvent.input.address.error'
                          )
                        )
                      ),
              },
            ]}
          >
            <GooglePlacesAutocomplete
              apiKey={'AIzaSyD1A6qbkuZkUr2JcR7HZGOINPg_QKACoSw'}
              selectProps={{
                // @ts-ignore
                eventAddress,
                onChange: handleChangeEventAddress,
                placeholder: '',
              }}
            />
          </Form.Item>

          <Form.Item className={'container-submit-btn'}>
            <Button
              type="primary"
              htmlType="submit"
              className={'submitButton'}
              disabled={!submittable}
              loading={props.isLoading}
            >
              {Translator.trans('form.registerEvent.submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default RegisterEvent;
