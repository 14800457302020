import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './components/ErrorPage/ErrorPage';
import SignUp from './app/SignUp';
import bottomLeft from './assets/img/background/bg-corner-bottom-left.png';
import topRight from './assets/img/background/bg-corner-top-right.png';
import { getAntdLocale } from './services/getTempLocale';
import { ToastContainer } from 'react-toastify';
import 'dayjs/locale/fr';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  const currentLocale = getAntdLocale();
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ff00a0',
        },
      }}
      locale={currentLocale}
    >
      <ErrorBoundary
        FallbackComponent={(exception) => <ErrorPage exception={exception} />}
      >
        <img className={'bottom-left'} src={bottomLeft} alt={'bottom-left'} />
        <img className={'top-right'} src={topRight} alt={'top-right'} />
        <Routes>
          <Route path="/ticketing" element={<SignUp />} />
          <Route path="/" element={<Navigate to={'ticketing'} />} />
        </Routes>
        <ToastContainer />
      </ErrorBoundary>
    </ConfigProvider>
  );
}

export default App;
