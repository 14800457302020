type MethodType = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

const getHeaders = (method: MethodType, body?: unknown): any => {
  return {
    method: method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : null,
  };
};

export const post = (url: string | URL, body?: unknown) => {
  return fetch(url, getHeaders('POST', body))
    .then(checkStatus)
    .catch(handleError);
};

export const getUrl = (path: string, args: any = {}, version = 'v2023-10/') => {
  let newPath = new URL(path, process.env.REACT_APP_URL_API + version);

  for (let key in args) {
    if (args.hasOwnProperty(key)) {
      if (typeof args[key] === 'object' && !Array.isArray(args[key])) {
        newPath.searchParams.append(
          key + '|' + args[key].operation,
          args[key].value
        );
      } else if (Array.isArray(args[key])) {
        args[key].forEach((data: any) => {
          newPath.searchParams.append(key + '|' + data.operation, data.value);
        });
      } else if (args[key] || args[key] === 0) {
        newPath.searchParams.append(key, args[key]);
      }
    }
  }

  return newPath;
};

const checkStatus = (response: any) => {
  if (response.status >= 200 && response.status < 300) {
    if (response.status === 204 || response.status === 205) {
      return null;
    }
    return response.json();
  }

  return response.json().then((json: any) =>
    Promise.reject({
      status: response.status,
      ok: false,
      statusText: response.statusText,
      body: json,
    })
  );
};

const handleError = (error: any) => {
  error.response = {
    status: 0,
    statusText:
      'Cannot connect. Please make sure you are connected to internet.',
  };
  throw error;
};
