import React, { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Dropdown, MenuProps } from 'antd';
import { getLocale } from '../../services/getTempLocale';
import AppLanguages from '../../lngProvider';

export const FlagSelection = () => {
  const [currentLanguage, setCurrentLanguage] = useState('');

  useEffect(() => {
    setCurrentLanguage(getLocale() === 'en' ? 'GB' : getLocale());
  }, []);

  const items: MenuProps['items'] = [];

  AppLanguages.forEach((language, index) => {
    items.push({
      label: (
        <ReactCountryFlag
          onClick={() => changeLanguage(language.locale)}
          svg
          countryCode={language.icon}
          style={{
            fontSize: '1.5em',
            lineHeight: '1.5em',
            opacity: currentLanguage === language.locale ? '0.3' : '1',
          }}
        />
      ),
      key: index,
      disabled: currentLanguage === language.locale,
    });
  });

  function changeLanguage(lang: string) {
    if (lang !== currentLanguage) {
      window.location.href = '/ticketing?lang=' + lang;
    }
  }

  return (
    <div className={'container-flag'}>
      <Dropdown menu={{ items }} arrow placement="bottom" trigger={['click']}>
        {currentLanguage ? (
          <ReactCountryFlag
            svg
            countryCode={currentLanguage}
            style={{
              fontSize: '1.5em',
              lineHeight: '1.5em',
              marginRight: '5px',
            }}
          />
        ) : (
          <div></div>
        )}
      </Dropdown>
    </div>
  );
};

export default FlagSelection;
