import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Form, Input } from 'antd';
import Translator from '../../../services/translator';
import Organizer from '../../../models/Organizer';

interface RegisterOrganizeProps {
  submitForm: (formValues: Organizer) => void;
  formOrganizer: Organizer;
  errors: any;
}

export const RegisterOrganizer = (props: RegisterOrganizeProps) => {
  const [formValues, setFormValues] = useState<Organizer>(new Organizer());
  const [form] = Form.useForm();
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);
  const formRef = useRef<any>(null);

  useEffect(() => {
    handleDisplayErrors(props.errors);
  }, [props.errors]);

  function handleDisplayErrors(errors: any) {
    let setErrors: any = [];
    if (errors && errors.lenght > 0) {
      errors.forEach((error: any) => {
        setErrors.push({
          name: error.propertyPath,
          errors: [error.title],
        });
      });
    }

    if (setErrors.length > 0) {
      formRef.current?.setFields(setErrors);
      formRef.current?.scrollToField(setErrors[0].name);
    }
  }

  function handleRemoveErrors(obj: any) {
    let errors: any = [];

    Object.keys(obj).map((key) =>
      errors.push({
        name: key,
        errors: '',
      })
    );

    formRef.current?.setFields(errors);
  }

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values, form]);

  function handleSubmit() {
    handleRemoveErrors(formRef.current?.getFieldsValue());
    props.submitForm(formValues);
  }

  return (
    <div className={'container-form'}>
      <div className={'form-title'}>
        {Translator.trans('form.registerOrganizer.title')}
      </div>
      <div className={'form-desc'}>
        {Translator.trans('form.registerOrganizer.desc')}
      </div>
      <div>
        <Form
          name="basic"
          onValuesChange={(_, allValues) => setFormValues(allValues)}
          onFinish={handleSubmit}
          layout="vertical"
          form={form}
          ref={formRef}
        >
          <Form.Item
            label={Translator.trans(
              'form.registerOrganizer.input.organization'
            )}
            name="organizationName"
            htmlFor={'text'}
            initialValue={props.formOrganizer.organizationName}
            rules={[{ required: true, message: 'Champ obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerOrganizer.input.firstname')}
            name="firstName"
            htmlFor={'text'}
            initialValue={props.formOrganizer.firstName}
            rules={[{ required: true, message: 'Champ obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerOrganizer.input.lastname')}
            name="lastName"
            htmlFor={'text'}
            initialValue={props.formOrganizer.lastName}
            rules={[{ required: true, message: 'Champ obligatoire' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={Translator.trans('form.registerOrganizer.input.email')}
            name="email"
            htmlFor={'email'}
            initialValue={props.formOrganizer.email}
            rules={[{ required: true, message: 'Champ obligatoire' }]}
          >
            <Input type={'email'} />
          </Form.Item>

          <Form.Item
            name="cgu"
            className={'checkbox-cgu'}
            valuePropName={'checked'}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('Veuillez cocher cette case pour continuer')
                      ),
              },
            ]}
          >
            <Checkbox>
              {Translator.transHtml('form.registerOrganizer.checkbox.cgu')}
            </Checkbox>
          </Form.Item>

          <Form.Item className={'container-submit-btn'}>
            <Button
              disabled={!submittable}
              type="primary"
              htmlType="submit"
              className={'submitButton'}
            >
              {Translator.trans('form.registerOrganizer.submit')}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default RegisterOrganizer;
